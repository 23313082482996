// Import custom Fuse and scss-environment variables
@import './style-variables.scss';

// Import app.theme.scss
@import 'app/app.theme';

@import '~quill/dist/quill.snow.css';

.bottomsheet-resource-preview {
  width: 80vw;
  display: flex !important;
  height: 80vh;
}

// DRAGULA
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

// END DRAGULA

// TABS WIDTH
.mat-tab-label {
  min-width: 40px !important;
}

.app-tab-alert:after {
  content: "*";
  position: absolute;
  transform: translate(20%, -20%);
  color: red;
  font-size: 24px;
  animation-name: appearAndDisappear;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes appearAndDisappear {
  0% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

// RIGHT SIDEBAR WIDTH
.page-layout.carded.right-sidebar>.sidebar {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
}

// RIGHT SIDEBAR + CENTER DIV NORMAL Z-INDEX
fuse-sidebar.locked-open {
  z-index: 3;
}

// CHROME 117 bugfix (flex-order: 2 on sidebar breaks z-index flow)
.page-layout.carded.left-sidebar>.sidebar.locked-open+.center,
.page-layout.carded.right-sidebar>.sidebar.locked-open+.center {
  z-index: 4;
}

// MAT-HEADER REMOVE DISABLED SORT ARROW
.mat-sort-header-disabled .mat-sort-header-arrow {
  display: none;
}

// GLOBAL SPINNER
app-spinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ellipsis-2 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.ellipsis-3 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.word-break-all {
  white-space: normal;
  word-break: break-all;
}

.word-break-all-deep * {
  white-space: normal;
  word-break: break-all;
}

.word-break {
  white-space: normal;
  word-break: break-word;
}

.lh1 {
  line-height: 1em;
}

.rtl {
  text-align: right;
  direction: rtl;
}

.white-bg {
  background: #fff;
}

// global hide
[hidden] {
  display: none !important;
}

.mat-form-field-infix {
  width: 100% !important;
}

// global overwrite right sidebar position {
.sidebar.right-positioned:not(.locked-open),
.sidebar.right-positioned:not(.locked-open)+.fuse-sidebar-overlay {
  position: fixed;

  @include media-breakpoint('xs') {
    top: 56px;
  }

  @include media-breakpoint('gt-xs') {
    top: 64px;
  }
}

// global table 100%
app-table {
  width: 100%;
  display: flex;
  height: 100%;
}

mat-cell,
mat-footer-cell,
mat-header-cell,
.mat-cell,
.mat-footer-cell,
.mat-header-cell,
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-size: 14px !important;
}

app-filter-injector,
.app-filter-injector {
  .mat-form-field-label-wrapper {
    // font-style: italic;
    font-size: 12px;
  }

  .mat-form-field-should-float {

    input[ng-reflect-model],
    [ng-reflect-model] .mat-select-value {
      font-weight: bold;
      color: #2d323e !important;
    }
  }
}

// global sidebar

fuse-sidebar {
  width: 320px !important;
  min-width: 320px !important;
  max-width: 320px !important;
}

// global fix input[type="date"]

// .mat-form-field-type-date:not(.mat-form-field-should-float) input {
//   color: transparent; // show only placeholder
// }

.app-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &.app-item-clickable {
    cursor: pointer;

    &:hover {
      background: #f5f5f5;
    }
  }

  &>mat-icon,
  &>app-icon>mat-icon {
    color: #aaa;
    font-size: 36px;
    height: 36px;
    width: 36px;
    margin-right: 8px;
    flex: 0 0 auto;
  }

  &>div {
    white-space: normal;
    overflow: hidden;
    flex: 1;

    .app-label {
      color: #999;
      font-size: 12px;
    }

    .app-value {
      font-size: 18px;

      &.app-value-sm {
        font-size: 16px;
      }

      @include media-breakpoint('xs') {
        font-size: 14px;
        font-weight: bold;
      }

      .app-value-suffix {
        font-size: 12px;
        display: inline-flex;
        white-space: nowrap;
        margin-left: 4px;
      }
    }

    .app-item-content {
      padding-top: 12px;
      overflow: auto;
    }
  }
}

.mat-form-field-invalid .mat-radio-label-content,
.mat-form-field-invalid label {
  color: #f44336;
}

.mat-form-field-invalid .mat-radio-outer-circle {
  border-color: #f44336 !important;
}

@keyframes onSubmitLoading {
  to {
    transform: rotate(360deg);
  }
}

.onsubmit-loading .mat-button-wrapper {
  visibility: hidden;
}

.onsubmit-loading:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: onSubmitLoading 0.8s linear infinite;
}

// DIALOG GLOBAL
// TODO: what is this?

@include media-breakpoint('xs') {
  // .cdk-global-overlay-wrapper {
  //   padding: 12px !important;
  // }

  // .cdk-overlay-pane {
  //   max-width: 100% !important;
  //   width: 100% !important;
  //   max-height: 100% !important;
  //   height: auto !important;
  // }
}

.mat-expansion-panel.mat-expanded:not(.ng-animating) {
  overflow: visible !important;
}

// overwrite default distance between panels when closed
mat-expansion-panel {
  &:not(.mat-expansion-panel-spacing):not(:last-child) {
    margin-bottom: 8px;
  }
}

@keyframes onLoading {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
    transform: rotate(360deg);
  }
}

.loading {
  position: relative;
}

.loading:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 4;
}

.loading:after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-top-color: #000000;
  animation: onLoading 0.8s linear infinite;
  z-index: 4;
}

.app-rotate {
  display: inline-block;
  transform-origin: center center;
  animation: onLoading 0.8s linear infinite;
}

// save floating button
.app-fixed-save-space {
  padding-bottom: 100px !important;
  max-height: fit-content !important;
}

.app-fixed-save {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 9999;

  .app-fixed-save-desktop {
    padding: 4px 16px;

    span {
      margin-right: 4px;
    }
  }
}

// SIDEBAR GLOBAL CHANGE
.page-layout.carded.left-sidebar>.sidebar .header,
.page-layout.carded.right-sidebar>.sidebar .header {
  display: flex;
  align-items: flex-end;

  &>* {
    width: 100%;
  }
}

// FORMLY FORM GENERIC

formly-group {
  formly-field {
    display: block;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

mat-form-field.mat-form-field-type-radio {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;

    formly-field-mat-radio {
      mat-radio-group {
        white-space: normal;

        .mat-radio-button:not(:last-child) {
          margin-right: 12px;
          margin-bottom: 12px;
        }
      }
    }
  }
}

mat-form-field.mat-form-field-type-color {
  max-width: 60px;
}

mat-form-field.mat-form-field-type-checkbox {
  width: auto !important;

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-infix {
    padding: 0;
    border: none;
  }

  .mat-form-field-label-wrapper,
  .mat-form-field-subscript-wrapper {
    display: none;
  }
}

formly-field .mat-form-field {
  width: 100%;
}

formly-field-mat-select {
  width: 100%;
}

// app-colorpicker {
//   max-width: 90px;
// }

ff-image-uploader {
  margin-bottom: 16px;
  display: block;
}

.app-input-label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  // transform: scale(0.825);
  // transform-origin: top left;
  // TARAS: WHY transform?
  font-size: 82.8%;
  line-height: 1.1em;
}

.app-input-required {
  display: block;

  &:after {
    content: '*';
    display: inline;
    margin-left: 3px;
    // BIGGER
    font-size: 1.8rem;
    line-height: 0.8em;
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
}

// MAT CARD TITLE FULL-WIDTH
.mat-card-header-text {
  width: 100%;
  margin-right: 0 !important;
}

mat-card-header {
  align-items: center;

  [mat-card-avatar] {
    text-align: center;
  }
}

.mat-accordion.mini {
  mat-expansion-panel-header {
    padding: 8px 12px !important;
    height: auto !important;
  }

  .mat-expansion-panel-body {
    padding: 0 12px 12px !important;
    white-space: normal;
  }
}

.advice {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: -8px;
    width: 8px;
    height: 8px;
    background: orange;
    border-radius: 50%;
  }
}

.opacized {
  opacity: 0.5;
}

$icon-btn-sm-size: 20px;

.app-icon-btn-sm {
  // background: yellow !important;
  width: ($icon-btn-sm-size + 4) !important;
  height: ($icon-btn-sm-size + 4) !important;
  line-height: ($icon-btn-sm-size + 4) !important;
  justify-content: center;

  mat-icon {
    // background: red !important;
    margin-top: -5px;
    width: $icon-btn-sm-size;
    height: $icon-btn-sm-size;
    font-size: $icon-btn-sm-size;
    min-width: $icon-btn-sm-size;
    min-height: $icon-btn-sm-size;
  }
}

$icon-btn-xs-size: 18px;

.app-icon-btn-xs {
  // background: yellow !important;
  width: $icon-btn-xs-size !important;
  height: $icon-btn-xs-size !important;
  line-height: $icon-btn-xs-size !important;

  mat-icon {
    // background: red !important;
    margin-top: -5px;
    width: $icon-btn-xs-size;
    height: $icon-btn-xs-size;
    font-size: $icon-btn-xs-size;
    min-width: $icon-btn-xs-size;
    min-height: $icon-btn-xs-size;
  }
}

$icon-xs-size: 16px;

.app-icon-xs {
  width: $icon-xs-size !important;
  height: $icon-xs-size !important;
  font-size: $icon-xs-size !important;
  line-height: $icon-xs-size !important;
  min-height: $icon-xs-size !important;
  min-width: $icon-xs-size !important;
}

$icon-lg-size: 32px;

.app-icon-lg {
  width: $icon-lg-size !important;
  height: $icon-lg-size !important;
  font-size: $icon-lg-size !important;
  line-height: $icon-lg-size !important;
  min-height: $icon-lg-size !important;
  min-width: $icon-lg-size !important;
}

$btn-xs: 32px;

.btn-xs,
.app-btn-toggle-group-xs .mat-button-toggle .mat-button-toggle-label-content {
  height: $btn-xs !important;
  line-height: $btn-xs !important;
}

// CALENDAR OVERRIDES

// Y GRID COLOR
.cal-month-view .cal-days .cal-cell-row {
  border-bottom-color: #e1e1e1;
}

.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: #e1e1e1;
}

.cal-month-view .cal-open-day-events {
  background-color: #e1e1e1;
  color: inherit;
  box-shadow: none;
}

.cal-month-view .cal-cell-row .cal-cell:not(.cal-open):hover {
  background-color: #ededed;
}

.cal-month-view .cal-day-cell.cal-today {
  background-color: #d3faff;
}

.cal-week-view .cal-event {
  color: inherit;
  font-weight: bold;
  white-space: normal;
  text-align: center;
}

// END CALENDAR

.capitalize {
  text-transform: capitalize;
}

.fluid-disabled {
  pointer-events: none;
  opacity: 0.5;
  user-select: none;
  cursor: not-allowed;
}

.fluid-checked {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 35%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(/assets/images/check.png) no-repeat;
    background-size: contain;
    background-position: center center;
  }
}

.text-break {
  white-space: normal;
  line-height: 1.2em;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.relative {
  position: relative;
}

.placeholder-square {
  display: block;
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .placeholder-square-content {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.placeholder-abs {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: repeating-linear-gradient(45deg,
      #fff,
      #fff 10px,
      #efefef 10px,
      #efefef 20px);
  border: 1px dashed #e2e2e2;
}

// CHART LABEL
.app-chart-legend-square {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  line-height: 1em;
}

.app-required:after {
  content: '*';
  font-size: 110%;
  line-height: 0px;
  transform: translate(3px, 0px);
  display: inline-block;
}

// COMMON PAGES SIDEBAR STYLE

.app-left-sidebar-max-width {
  @include media-breakpoint('gt-sm') {
    max-width: 300px !important;
  }
}

// TOOLTIPS

.app-tooltip-wide {
  max-width: 100% !important;
}

.app-tooltip-multiline {
  white-space: normal !important;
  word-break: break-all !important;
}

.app-tooltip-pre {
  white-space: pre !important;
  word-break: break-all !important;
}

.text-barred {
  text-decoration: line-through;
}

.app-tag {
  font-size: 10px;
  text-transform: uppercase;
  padding: 3px 5px;
  border-radius: 5px;
  background: #c2c2c2;
  color: #666;
}

// GLOBAL DSP LOGOS STYLE
.dsp-logo {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;

  &.dsp-logo-sm {
    width: 16px;
    height: 16px;
  }

}

.dsp-logo.hivestack {
  background-image: url(/assets/images/logos/dsp/hivestack_logo.png);
}

.dsp-logo.pladway {
  background-image: url(/assets/images/logos/dsp/pladway_logo.png);
}

.dsp-logo.framen {
  background-image: url(/assets/images/logos/dsp/framen_logo.png);
}

.app-inputs-label-sm {
  font-size: 75%;
  color: rgba(0, 0, 0, 0.51);
  font-weight: 500;
}

// COLORPICKER OVERRIDE

.color-picker {
  z-index: 1001 !important;
}

.app-highlight-mark:after {
  content: "*";
  // position: absolute;
  // transform: translate(20%, -20%);
  color: #fb8c00;
  font-size: 24px;
}

@keyframes pulseLoading {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.app-rotate-slow {
  display: inline-block;
  transform-origin: center center;
  animation: onLoading 1.5s linear infinite;
}