
//Include all base classes
@use '@angular/material' as mat;
@import './base.classes';
//Importing main fluid-theme, with #theme variable
@import './fluid.theme';
//Fuse core
@import '@fuse/scss/core.scss';
// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import "./modules/layout/components/chat-panel/chat-panel.theme.scss";
@import "src/app/modules/layout/components/toolbar/toolbar.theme.scss";
@import "src/app/modules/resources/components/resources-picker/resources.picker.theme.scss";
@import "src/app/modules/resources/components/resource-preview/resource-preview.theme.scss";
@import "src/app/modules/uploader/components/resources-uploader/resources-uploader.theme.scss";

/* @import 'src/app/main/apps/academy/academy.theme';
@import 'src/app/main/apps/calendar/calendar.theme';
@import 'src/app/main/apps/chat/chat.theme';
@import 'src/app/main/apps/contacts/contacts.theme';
@import 'src/app/main/apps/dashboards/project/project.theme';
@import 'src/app/main/apps/file-manager/file-manager.theme';
@import 'src/app/main/apps/mail/mail.theme';
@import 'src/app/main/apps/mail-ngrx/mail.theme';
@import 'src/app/main/apps/scrumboard/scrumboard.theme';
@import 'src/app/main/apps/todo/todo.theme';
 */
@import "src/app/core/auth/pages/login/login.theme";
@import "src/app/core/auth/pages/forgot-password/forgot-password.theme";
/* @import 'src/app/main/pages/authentication/register/register.theme';
@import 'src/app/main/pages/authentication/register-2/register-2.theme';
@import 'src/app/main/pages/authentication/reset-password/reset-password.theme';
@import 'src/app/main/pages/authentication/reset-password-2/reset-password-2.theme';
@import 'src/app/main/pages/authentication/forgot-password/forgot-password.theme';
@import 'src/app/main/pages/authentication/forgot-password-2/forgot-password-2.theme';
@import 'src/app/main/pages/authentication/lock/lock.theme';
@import 'src/app/main/pages/authentication/mail-confirm/mail-confirm.theme';
@import 'src/app/main/pages/coming-soon/coming-soon.theme';
@import 'src/app/main/pages/errors/404/error-404.theme';
@import 'src/app/main/pages/errors/500/error-500.theme';
@import 'src/app/main/pages/maintenance/maintenance.theme';
@import 'src/app/main/pages/pricing/style-1/style-1.theme';
@import 'src/app/main/pages/pricing/style-2/style-2.theme';
@import 'src/app/main/pages/pricing/style-3/style-3.theme';
@import 'src/app/main/pages/profile/tabs/about/about.theme';
@import 'src/app/main/pages/profile/tabs/photos-videos/photos-videos.theme';
@import 'src/app/main/pages/profile/tabs/timeline/timeline.theme';
@import 'src/app/main/pages/faq/faq.theme';
@import 'src/app/main/pages/knowledge-base/knowledge-base.theme'; */

//@import 'src/app/main/documentation/components/cards/cards.theme';

//@import 'src/app/main/angular-material-elements/example-viewer/example-viewer.theme';

// Define a mixin for easier access
@mixin components-theme($theme) {
    // Layout components
    @include chat-panel-theme($theme);
    @include toolbar-theme($theme);
    @include resources-picker-theme($theme);
    @include resources-preview-theme($theme);
    @include resources-uploader-theme($theme);

    // Apps
    /* @include academy-theme($theme);
  @include calendar-theme($theme);
  @include chat-theme($theme);
  @include contacts-theme($theme);
  @include dashboards-project-theme($theme);
  @include file-manager-theme($theme);
  @include mail-theme($theme);
  @include mail-ngrx-theme($theme);
  @include scrumboard-theme($theme);
  @include todo-theme($theme); */

    // Pages

    @include login-theme($theme);
    @include forgot-password-2-theme($theme);
    /* @include register-theme($theme);
    @include register-2-theme($theme);
    @include reset-password-theme($theme);
    @include reset-password-2-theme($theme);
    @include forgot-password-theme($theme);
    @include lock-theme($theme);
    @include mail-confirm-theme($theme);
    @include coming-soon-theme($theme);
    @include error-404-theme($theme);
    @include error-500-theme($theme);
    @include maintenance-theme($theme);
    @include pricing-style-1-theme($theme);
    @include pricing-style-2-theme($theme);
    @include pricing-style-3-theme($theme);
    @include profile-about-theme($theme);
    @include profile-photos-videos-theme($theme);
    @include profile-timeline-theme($theme);
    @include faq-theme($theme);
    @include knowledge-base-theme($theme); */

    // Docs
    //@include docs-components-cards-theme($theme);

    // Example Viewer
    //@include example-viewer-theme($theme);
}

// Add ".theme-fluid" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-fluid {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);

    // Apply the theme to the user components
    @include components-theme($theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $default-primary-palette,
        accent: $default-accent-palette,
        warn: $default-warn-palette
    );

    @include fuse-color-classes($palettes);
}
