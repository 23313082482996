@mixin resources-preview-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  resource-preview-content {
    .content {
      background-color: map-get($background, hover);
      border-color: map-get($foreground, divider);
      border-width: 1px;
      border-style: solid;
    }
    .metadata {
      border-color: map-get($foreground, divider);
      border-width: 1px;
      border-style: solid;
    }
  }
}
