@mixin resources-picker-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $accent: map-get($theme, accent);
  resources-picker {
    .main-template {
      border-color: map-get($foreground, divider);
      aside {
        .toolbar,
        mat-list-item {
          border-color: map-get($foreground, divider);
        }
        mat-list-item {
          &:hover {
            background-color: map-get($background, hover);
          }
          &.current {
            background-color: map-get($accent, default);
            color: white;
          }
        }
      }
      .resources {
        border-color: map-get($foreground, divider);
        .content-card {
          &:hover {
            background-color: map-get($background, hover);
          }
          &.current {
            background-color: map-get($accent, default);
            color: white;
          }
        }
        footer {
          border-color: map-get($foreground, divider);
          background: map-get($background, hover);
        }
      }
    }
  }
}
